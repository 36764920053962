<template>
    <v-card class="fill-height" fluid>
        <v-toolbar dark color="primary">
            <router-link :to="{ path: '/' }" tag="v-btn">
                <v-btn icon dark>
                    <v-icon>close</v-icon>
                </v-btn>
            </router-link>
            <v-toolbar-title>{{ $t("Falta pouco!") }}</v-toolbar-title>
        </v-toolbar>

        <v-container class="text-center" v-if="input.token">
            <v-card class="mx-auto mt-12" max-width="800" color="secondary" dark>
                <v-card-title>
                    <v-icon large left> settings_applications </v-icon>
                    <span class="title font-weight-light">{{ $t("Criando senha de acesso.") }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" @submit.prevent="send">
                        <v-text-field v-model="input.email" label="E-mail" class="my-8" required disabled />
                        <v-text-field
                            :label="$t('Digite a senha')"
                            v-model="input.password"
                            min="8"
                            :rules="[rules.required, rules.password]"
                            :append-icon="show1 ? 'visibility' : 'visibility_off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            counter
                            required
                        />
                        <v-text-field
                            :label="$t('Confirme a senha')"
                            v-model="confirmPassword"
                            min="8"
                            :rules="confirmPasswordRules"
                            :append-icon="show2 ? 'visibility' : 'visibility_off'"
                            :type="show2 ? 'text' : 'password'"
                            @click:append="show2 = !show2"
                            counter
                            required
                        />
                        <v-switch v-model="keep" inset color="success" :label="$t('Manter logado')" />
                        <v-btn color="primary" @click="send" :loading="sending">{{ $t("Salvar") }}</v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-container>
        <v-container v-else>
            <v-row dense>
                <v-col cols="12">
                    <v-card color="primary" dark>
                        <v-card-title class="headline">{{ $t("Infelizmente o link está vencido.") }}</v-card-title>

                        <v-card-subtitle>
                            {{
                                $t(
                                    "Para sua segurança o link de acesso tem validade de 30 minutos. Para concluir o cadastro clique em 'Reenviar' e verifique novamente seu e-mail."
                                )
                            }}
                        </v-card-subtitle>

                        <v-card-actions>
                            <v-btn color="accent" @click="resend" :loading="sending">{{ $t("Reenviar") }}</v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-progress-linear v-if="sending" indeterminate></v-progress-linear>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import jwt from "@/helpers/jwt";
import rules from "@/helpers/rules";

export default {
    name: "Register",
    created() {
        this.$store.commit("CLEAR_LOGIN");
        if (this.$route.params.token) {
            this.input.email = jwt.get(this.$route.params.token, "email");
            if (jwt.checkToken(this.$route.params.token)) {
                this.redirect = jwt.get(this.$route.params.token, "redirect") || this.$store.state.routeBeforeRegister;
                this.input.token = this.$route.params.token;
            }
        }
    },
    data() {
        return {
            jwt,
            rules,
            valid: true,
            sending: false,
            confirmPassword: null,
            show1: false,
            show2: false,
            keep: true,
            redirect: "/",
            input: {
                email: null,
                password: null,
                token: null,
            },
            confirmPasswordRules: [(v) => v === this.input.password || this.$t("Senha diferentes")],
        };
    },
    methods: {
        forceLogin() {
            this.sending = true;
            this.$http
                .post("auth", this.input)
                .then((response) => {
                    this.$store.commit("SET_LOGIN", { response, email: this.input.email, remember: this.keep, password: this.keep ? this.input.password : null });

                    this.$emit("login");
                    this.$router.replace({ path: this.redirect });
                })
                .catch((error) => {
                    this.sending = false;
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Usuário e/ou Senha incorretos."));
                    this.$router.replace({ path: "/login" });
                });
        },
        send() {
            if (this.$refs.form.validate()) {
                this.sending = true;
                this.$http
                    .post("confirm-doctor-register", this.input)
                    .then((result) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Cadastro concluído com sucesso."));
                        this.forceLogin();
                    })
                    .catch((error) => {
                        this.sending = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Não foi possível enviar sua solicitação."));
                    });
            }
        },
        resend() {
            this.sending = true;
            this.$http
                .post("doctor-register", { email: this.input.email })
                .then((result) => {
                    this.$router.replace({ path: "/login" });
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Solicitação enviada com sucesso."));
                })
                .catch((error) => {
                    this.sending = false;
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Não foi possível enviar sua solicitação."));
                });
        },
    },
};
</script>